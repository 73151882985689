import React from "react";
import Seo from "../../common/SEO";
import Layout from "../../common/Layout";
import SectionTitle from "../sectionTitle/SectionTitle";
import ContactOne from "./ContactOne";
import { useTranslation } from "react-i18next";

const Contact = () => {
  const { t } = useTranslation("Contact");
  return (
    <>
      <Seo
        title={t(
          "Contact - Need more information? Contact us - We’re here to help answer your questions. Our experts are on hand to help inform you of every aspect regarding your topic."
        )}
      />
      <Layout>
        <div className="main-content">
          {/* Start Contact Area  */}
          <div className="rwt-contact-area">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 mb--40 mt--80">
                  <SectionTitle
                    textAlign="text-center"
                    radiusRounded=""
                    subtitle={t("Contact Form")}
                    title={t("Need more information? Contact us.")}
                    description={t(
                      "We’re here to help answer your questions. Our experts are on hand to help inform you of every aspect regarding your topic."
                    )}
                  />
                </div>
              </div>
              <ContactOne />
            </div>
          </div>
          {/* End Contact Area  */}
        </div>
      </Layout>
    </>
  );
};
export default Contact;
