import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import useRecaptcha from "../../hooks/useRecaptcha";
import styles from "./WhitePaperForm.module.scss";

const initialContactValues = {
  name: "",
  email: "",
  phone_number: "",
  company: "",
  tag: "whitepaper",
};

function WhitePaperForm({ setSent }) {
  const { t } = useTranslation("WhitePaper");
  const [contactInfos, setContactInfos] = useState(initialContactValues);
  const [feedback, setFeedback] = useState({ type: "", message: "" });

  const { Recaptcha, recaptchaAction } = useRecaptcha({
    action: (token) => handleGetWhitePaper(token),
  });

  const handleGetWhitePaper = (token) => {
    async function action() {
      const url = `https://newsletter.predictalab.fr/newsletter/sub`;

      const response = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email_form: contactInfos,
          g_recaptcha_response: token,
        }),
      });

      if (!response.ok) {
        throw new Error(`${response.status} - ${response.statusText}`);
      }

      return await response.json();
    }

    action()
      .then(() => {
        setContactInfos(initialContactValues);
        setSent();
      })
      .catch((error) => {
        setFeedback({
          type: "error",
          message: error ?? t("Error occured, please try again later"),
        });
      });
  };

  const handleSetInfos = (e) => {
    setContactInfos((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  return (
    <form
      onSubmit={(e) => recaptchaAction(e)}
      className={styles.whitepaperform}
    >
      <div className="form-group" data-required="true">
        <input
          type="text"
          name="name"
          placeholder={t("Your Name", { ns: "Contact" })}
          required
          value={contactInfos.name}
          onChange={handleSetInfos}
        />
      </div>

      <div className="form-group" data-required="true">
        <input
          type="email"
          name="email"
          placeholder={t("Email Address", { ns: "Contact" })}
          required
          value={contactInfos.email}
          onChange={handleSetInfos}
        />
      </div>

      <div className="form-group">
        <input
          type="text"
          name="phone_number"
          placeholder={t("Phone Number", { ns: "Contact" })}
          value={contactInfos.phone_number}
          onChange={handleSetInfos}
        />
      </div>

      <div className="form-group">
        <input
          type="text"
          name="company"
          placeholder={t("Company")}
          value={contactInfos.company}
          onChange={handleSetInfos}
        />
      </div>

      <small className={styles.requiredNotice}>* {t("required fields")}</small>

      <div className="form-group">
        <div className="row align-items-center">
          <div className="col-12 col-md-auto">
            <button className="btn-default btn-large">
              {t("Download free white paper")}
            </button>
          </div>

          {feedback.message && (
            <div className="col-12 col-md mt-4 mt-md-0">
              <div
                className={
                  feedback.type === "success" ? "text-success" : "text-danger"
                }
                role="alert"
              >
                {t(feedback.message)}
              </div>
            </div>
          )}
        </div>
      </div>

      {Recaptcha}
    </form>
  );
}
export default WhitePaperForm;
