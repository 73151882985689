import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { useTranslation } from "react-i18next";

const ServicesItem = ({ data }) => {
  const { t } = useTranslation("ServicesItem");
  return (
    <div className="row row--15 service-wrapper">
      {data.map((val, i) => (
        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
          <ScrollAnimation
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}
          >
            <div
              className={`service service__style--1 bg-color-blackest radius mt--25 text-center`}
            >
              <div className="icon">{val.icon}</div>
              <div className="content">
                <h4 className="title w-500 text-balance">{t(val.title)}</h4>
                <p
                  className="description color-gray mb--0 text-balance"
                  dangerouslySetInnerHTML={{ __html: t(val.description) }}
                ></p>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      ))}
    </div>
  );
};
export default ServicesItem;
