import Title from "../../elements/sectionTitle/Title";
import BlogList from "./itemProp/BlogList";
import { useState, useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";

const Blog = () => {
  const { t } = useTranslation("Blog");
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    fetch("https://secret.predictalab.fr/rss/convert")
      .then((response) => response.json())
      .then((data) => {
        // Only keep the last 6 articles
        const articles = data.slice(0, 6);
        setArticles(articles);
      })
      .catch((error) => console.log(error));

    return () => {
      setArticles([]);
    };
  }, []);

  return (
    <>
      {articles.length > 0 && (
        <div className="blog-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <Title
                  textAlign="text-center"
                  title={
                    <Trans t={t}>
                      Our latests
                      <span className="theme-gradient"> articles</span>
                    </Trans>
                  }
                  description={t(
                    "Follow us on Medium to read about cool OSINT tools, geolocation challenges and tips to protect your privacy."
                  )}
                />
              </div>
            </div>
            <div className="row row--15">
              {articles.map((a) => (
                <div key={a.title} className="col-lg-4 col-md-6 col-12 mt--30">
                  <BlogList StyleVar="box-card-style-default" data={a} />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Blog;
