import React from "react";
import { FiCheck } from "react-icons/fi";
import ScrollAnimation from "react-animate-on-scroll";
import Title from "../sectionTitle/Title";
import { useTranslation } from "react-i18next";

const ProductsItem = ({ id, image, title, content, link, linkText }) => {
  const { t } = useTranslation("ProductsItem");

  return (
    <div className="products about-style-4 rn-section-gap" id={id}>
      <div className="container">
        <div className="row mb--40">
          <div className="col">
            <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
              <Title textAlign="text-center" title={title} />
            </ScrollAnimation>
          </div>
        </div>
        <div className="row row--40 align-items-center">
          <div className="col-lg-6">
            <img src={image} alt={title} />
          </div>
          <div className="col-lg-6 mt_md--40 mt_sm--40">
            <div className="content">
              <div className="inner">
                <ul className="feature-list">
                  {content.map((c) => (
                    <li key={c.title}>
                      <div className="icon">
                        <FiCheck />
                      </div>
                      <div className="title-wrapper">
                        <h4 className="title">{t(c.title)}</h4>
                        <p className="text">{t(c.desc)}</p>
                      </div>
                    </li>
                  ))}
                </ul>
                <div className="about-btn mt--30">
                  <a
                    className="btn-default ml--40"
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t(linkText)}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsItem;
