import React from "react";
import { useTranslation } from "react-i18next";
import { FiArrowRight } from "react-icons/fi";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

const HomepageProducts = () => {
  const { t } = useTranslation("HomepageProducts");
  return (
    <div>
      <Tabs>
        <div className="row row--30">
          <div className="col-lg-8">
            <div className="advance-tab-content advance-tab-content-1">
              <TabPanel>
                <div className="tab-content">
                  <div className="inner">
                    <div className="thumbnail">
                      <img
                        src="./images/products/predictasearch.jpeg"
                        alt={t(
                          "Predicta Search - Get the digital footprint from an email or phone number"
                        )}
                      />
                    </div>
                  </div>
                </div>
              </TabPanel>

              <TabPanel>
                <div className="tab-content">
                  <div className="inner">
                    <div className="thumbnail">
                      <img
                        src="./images/products/predictagraph.jpg"
                        alt={t(
                          "Predicta Graph - Explore and analyse data, a real investigation tool"
                        )}
                      />
                    </div>
                  </div>
                </div>
              </TabPanel>

              <TabPanel>
                <div className="tab-content">
                  <div className="inner">
                    <div className="thumbnail">
                      <img
                        src="./images/products/dorkgpt.jpeg"
                        alt={t("DorkGPT - Generate Google Dorks with AI")}
                      />
                    </div>
                  </div>
                </div>
              </TabPanel>
            </div>
          </div>
          <div className="col-lg-4 mt_md--30 mt_sm--30">
            <div className="advance-tab-button advance-tab-button-1 right-align">
              <TabList className="tab-button-list">
                <Tab>
                  <div className="tab-button">
                    <h4 className="title">Predicta Search</h4>
                    <p className="description">
                      {t(
                        "This reverse email & phone search engine helps you manage your digital footprint."
                      )}
                    </p>
                    <a
                      href="https://www.predictasearch.com"
                      target="_blank"
                      rel="noreferrer"
                      className="link mt--10"
                    >
                      www.predictasearch.com
                      <FiArrowRight />
                    </a>
                  </div>
                </Tab>
                <Tab>
                  <div className="tab-button">
                    <h4 className="title">Predicta Graph</h4>
                    <p className="description">
                      {t(
                        "Graph solution, explore and analyse data using our own APIs."
                      )}
                    </p>
                    <a
                      href="https://www.predictagraph.com"
                      target="_blank"
                      rel="noreferrer"
                      className="link mt--10"
                    >
                      www.predictagraph.com
                      <FiArrowRight />
                    </a>
                  </div>
                </Tab>
                <Tab>
                  <div className="tab-button">
                    <h4 className="title">DorkGPT</h4>
                    <p className="description">
                      {t("Generate the perfect search query with AI.")}
                    </p>
                    <a
                      href="https://www.dorkgpt.com"
                      target="_blank"
                      rel="noreferrer"
                      className="link mt--10"
                    >
                      www.dorkgpt.com
                      <FiArrowRight />
                    </a>
                  </div>
                </Tab>
              </TabList>
            </div>
          </div>
        </div>
      </Tabs>
    </div>
  );
};
export default HomepageProducts;
