import React from "react";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import { useTranslation } from "react-i18next";

const ServiceList = [
  {
    image: "/images/services/investigations.jpg",
    title: "Investigations",
    description: "We provide the insights you need to make decisions.",
    link: "/services#investigations",
  },
  {
    image: "/images/services/dev.jpg",
    title: "Software development",
    description:
      "We build the OSINT tools you need to optimize your time & work.",
    link: "/services#development",
  },
  {
    image: "/images/services/formation.jpg",
    title: "Training courses",
    description: "We teach you the bases with a multi-module OSINT course.",
    link: "/services#trainingcourses",
  },
];
const HomepageServices = () => {
  const { t } = useTranslation("HomepageServices");
  return (
    <div className="row row--15 service-wrapper">
      {ServiceList.map((val, i) => (
        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
          <ScrollAnimation
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}
          >
            <div className={`service service__style--2 text-center`}>
              <Link to={val.link}>
                <div className="inner">
                  <div className="image">
                    <img src={`${val.image}`} alt={val.description} />
                  </div>
                  <div className="content">
                    <h4 className="title">{t(val.title)}</h4>
                    <p className="description b1 color-gray mb--0">
                      {t(val.description)}
                    </p>
                  </div>
                </div>
              </Link>
            </div>
          </ScrollAnimation>
        </div>
      ))}
    </div>
  );
};
export default HomepageServices;
