import styles from "./useBookingModal.module.scss";
import { useTranslation } from "react-i18next";
import { useMemo, useState } from "react";
import { IoCloseCircleOutline as CloseIcon } from "react-icons/io5";
import { FiArrowRight } from "react-icons/fi";
import { createPortal } from "react-dom";

const useBookingModal = ({ showButton, asLink }) => {
  const { t } = useTranslation("BookingModal");
  const [opened, setOpened] = useState(false);

  const BookingModal = useMemo(
    () => (
      <>
        {showButton && (
          <button
            onClick={() => setOpened(true)}
            className={
              asLink
                ? "btn-default btn-small btn-border"
                : "btn-default btn-icon"
            }
          >
            {t("Book a meeting")}
            {!asLink && (
              <i className="icon">
                <FiArrowRight />
              </i>
            )}
          </button>
        )}

        {createPortal(
          <div className={styles.iframeWrapper} data-visible={opened}>
            <div className={styles.close} onClick={() => setOpened(false)}>
              <CloseIcon />
            </div>
            <iframe
              title="Booking"
              className={styles.iframe}
              src="https://app.simplymeet.me/predictalab/predictalab?is_widget=1&view=compact"
            />
          </div>,
          document.body
        )}
      </>
    ),
    [asLink, opened, showButton, t]
  );

  return {
    BookingModal,
    opened,
    setOpened,
  };
};

export default useBookingModal;
