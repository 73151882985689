import React from "react";
import SEO from "../common/SEO";
import Layout from "../common/Layout";
import Breadcrumb from "../elements/breadcrumb/Breadcrumb";
import Separator from "../elements/separator/Separator";

const DataProtectionPolicy = () => {
  return (
    <>
      <SEO title="Data protection policy" />
      <Layout>
        <Breadcrumb
          title="Data protection policy"
          rootUrl="/"
          parentUrl="Home"
          currentUrl="Data protection policy"
        />

        <div className="main-content">
          <div className="products about-style-4 rn-section-gap">
            <div className="container">
              <div className="row">
                <div className="col">
                  <small className="mb--30">
                    Updated version as of December 12, 2022
                  </small>

                  <p>
                    PREDICTA LAB is committed to ensuring that the processing of
                    personal data carried out as part of its activities complies
                    with the General Data Protection Regulation ("GDPR") as well
                    as the French Data Protection Act. PREDICTA LAB processes
                    your personal data in accordance with this data protection
                    policy ("Policy") and any instructions provided by third
                    parties in the context of its subcontracting activities.
                  </p>

                  <p>The third parties are:</p>
                  <ul>
                    <li>
                      Customers using PREDICTA LAB's products and services to
                      facilitate the verification of a person's digital
                      footprint.
                    </li>
                  </ul>
                  <p>
                    In this Policy, we explain how and why we use your data,
                    what your rights are, and how to exercise them.
                  </p>
                  <p>
                    By accepting the Policy, you consent to the processing of
                    your data.
                  </p>

                  <Separator />

                  <h2>What is personal data?</h2>
                  <p>
                    Personal data ("Personal Data" or "Data") refers to any
                    information relating to an identified or identifiable
                    natural person:
                  </p>
                  <ul>
                    <li>Directly (name, first name, email address)</li>
                    <li>
                      Or indirectly (IP address, customer account number).
                    </li>
                  </ul>

                  <Separator />

                  <h2>Who does this Data Protection Policy apply to?</h2>
                  <p>
                    This Policy applies to all our products and services (the
                    "Products and Services"), which include our services
                    facilitating the verification of a person's digital
                    footprint, monitoring social networks to combat
                    disinformation, and writing articles, as presented on our
                    website www.predictalab.com (the "Site").
                  </p>
                  <p>
                    Our Policy aims to inform any person using our Products and
                    Services or visiting the Site (the "User" or the "Client").
                  </p>

                  <Separator />

                  <h2>Who collects your Data?</h2>

                  <p>
                    The data controller, within the meaning of the GDPR, is SAS
                    PREDICTA LAB, represented by Baptiste Robert as president.
                  </p>

                  <Separator />

                  <h2>How do we collect your Data?</h2>
                  <p>
                    PREDICTA LAB collects your Data online (including by email),
                    on paper, or orally (during a physical appointment, video
                    conference, or by phone), and this Policy applies regardless
                    of the method of collection or processing.
                  </p>
                  <p>
                    The Personal Data that the User agrees to provide to us,
                    regardless of the method of collection (online, on paper, or
                    orally), is subject to the provisions of this Policy.
                  </p>

                  <Separator />

                  <h2>Why do we collect your Data?</h2>

                  <p>
                    Some of your Data is necessary for the processing and
                    provision of our Products and Services. Failure to provide
                    the information prevents the proper functioning of our
                    Products and Services.
                  </p>
                  <p>
                    For informational purposes, you will find below a list of
                    categories of collected data corresponding to the purposes
                    pursued by our collection, as well as the legal basis for
                    these collections.
                  </p>

                  <h3>To respond to your contact request:</h3>

                  <p>
                    Data categories collected: identity data (name, surname),
                    contact data (email address). Legal basis: legitimate
                    interest in providing you with our Products and Services.
                  </p>

                  <h3>To respond to your quote request:</h3>
                  <ul>
                    <li>
                      Data categories collected: identity data, contact data,
                      quote number, professional life.
                    </li>
                    <li>
                      Legal basis: pre-contractual measures taken at your
                      request.
                    </li>
                  </ul>

                  <h3>
                    To enable you to order and use our Products and Services:
                  </h3>
                  <ul>
                    <li>
                      Data categories collected: identity data (name, surname),
                      contact data (email address, phone number), customer data
                      (purchase order, billing and shipping address),
                      professional life.
                    </li>
                    <li>
                      Legal basis: contractual measures taken at your request.
                    </li>
                  </ul>

                  <h3>To manage customer billing:</h3>
                  <ul>
                    <li>
                      Data categories collected: identity data (name, surname),
                      contact data (email address, phone number), customer data
                      (purchase order, billing and shipping address),
                      professional life, economic and financial data.
                    </li>
                    <li>
                      Legal basis: contractual measures taken at your request.
                    </li>
                  </ul>

                  <h3>
                    When creating a customer account to use our Products and
                    Services:
                  </h3>
                  <ul>
                    <li>
                      Data categories collected: identity data (name, surname)
                      and contact data (email address), password.
                    </li>
                    <li>
                      Legal basis: contractual measures taken at your request.
                    </li>
                  </ul>

                  <h3>To offer you a subscription to our newsletter:</h3>
                  <p>
                    You can subscribe to our newsletter via the link provided
                    for this purpose on our Website.
                  </p>
                  <ul>
                    <li>
                      Data categories collected: identity data (name, surname)
                      and contact data (email address).
                    </li>
                    <li>
                      Legal basis: your consent when you check the dedicated
                      box, when you subscribe to the newsletter.
                    </li>
                  </ul>
                  <p>
                    You can unsubscribe from this newsletter at any time by
                    clicking on the link provided for this purpose, which is
                    present in each of the newsletters.
                  </p>

                  <h3>To process your response to a job offer:</h3>
                  <ul>
                    <li>
                      Data categories collected: identity data (name, surname),
                      contact data (email address, phone number), information
                      contained in the curriculum vitae and cover letter
                      (photograph, professional background).
                    </li>
                    <li>
                      Legal basis: pre-contractual measures taken at your
                      request.
                    </li>
                  </ul>
                  <h3>
                    To conduct statistical traffic operations on our website:
                  </h3>
                  <ul>
                    <li>
                      Data categories collected: browsing data (IP address).
                    </li>
                    <li>
                      Legal basis: your consent when you accept the use of
                      statistical cookies.
                    </li>
                  </ul>
                  <Separator />
                  <h2>Are you required to provide your Personal Data?</h2>
                  <p>
                    When you subscribe to our newsletter or request a quote, the
                    processing of certain data related to your identity and
                    contact information is mandatory. Indeed, we cannot proceed
                    with your subscription or provide a quote without processing
                    this data.
                  </p>
                  <p>
                    Please note that when you give your consent to the
                    processing of your Personal Data, you have the option to
                    withdraw this consent at any time via the unsubscribe links
                    provided in each promotional email.
                  </p>
                  <Separator />
                  <h2>What are your rights?</h2>
                  <p>
                    Subject to compliance with the legal obligations imposed on
                    PREDICTA LAB in the context of its activities, each
                    individual has the following rights:
                  </p>
                  <ul>
                    <li>
                      Request a copy of the Data from your customer account -
                      Right of access
                    </li>
                    <li>
                      Correct any inaccurate Data concerning you - Right of
                      rectification
                    </li>
                    <li>
                      Request the erasure of certain Data concerning you - Right
                      to erasure
                    </li>
                    <li>
                      Object to the processing of your Data; please note that
                      this request is incompatible with the processing of your
                      order for Products and Services - Right to object
                    </li>
                    <li>
                      Decide at any time to withdraw your consent, for example
                      via the unsubscribe link provided in each newsletter -
                      Right of withdrawal
                    </li>
                    <li>
                      Request the restriction of the use of your Data - Right to
                      restriction of processing
                    </li>
                    <li>
                      Object to the profiling of your Data - Right to object to
                      profiling
                    </li>
                    <li>
                      Exercise your right to data portability - for example, if
                      you are a customer and wish to change providers and
                      migrate your Data to another software
                    </li>
                  </ul>
                  <p>
                    In the event of a Data breach that could result in a high
                    risk to your rights and freedoms, you will be informed of
                    this breach without undue delay.
                  </p>
                  <p>
                    You can provide instructions regarding the storage, erasure,
                    and disclosure of your Data after your death, in accordance
                    with Article 40-1 of the Data Protection Act.
                  </p>
                  <Separator />
                  <h2>How to exercise your rights?</h2>
                  <p>
                    These rights, as well as these instructions, can be
                    exercised and addressed by writing to us:
                  </p>
                  <ul>
                    <li>
                      By postal mail to the following address:
                      <address>
                        <ul>
                          <li>Predicta Lab</li>
                          <li>Attention: Data Protection Officer (DPO)</li>
                          <li>777 chemin de L’Espérès</li>
                          <li>31370 BEAUFORT</li>
                        </ul>
                      </address>
                    </li>
                    <li>Or by email: rgpd@predictalab.com</li>
                  </ul>
                  <p>
                    In order to allow us to quickly identify you and respond to
                    you, please include any elements that can establish your
                    identity with your request (such as customer number or
                    request made directly through your account). A response will
                    be sent to you within 1 month following the receipt of the
                    request. In certain cases, due to the complexity of the
                    request or the number of requests, this period may be
                    extended by 2 months.
                  </p>
                  <p>
                    You can also contact the National Commission for Data
                    Protection and Liberties (CNIL), the regulatory authority
                    responsible for enforcing personal data protection
                    regulations in France, online at{" "}
                    <a
                      href="https://www.cnil.fr/fr/agir"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      https://www.cnil.fr/fr/agir
                    </a>{" "}
                    or by postal mail to the following address: Commission
                    Nationale de l'Informatique et des Libertés, 3 Place de
                    Fontenoy - TSA 80715, 75334 PARIS CEDEX.
                  </p>
                  <Separator />
                  <h2>How long do we retain your Data?</h2>
                  <p>
                    We only keep your Data for as long as necessary to carry out
                    the operations for which they were collected, unless the law
                    provides for a different retention period.
                  </p>
                  <p>
                    For example, in the context of sending newsletters or
                    commercial prospecting, we retain your Data for a period of
                    3 years from our last contact, in accordance with the
                    recommendations of the CNIL.
                  </p>
                  <p>
                    You acknowledge that the Data you provide to us and that is
                    stored in our information systems is accurate and
                    constitutes proof of your identity.
                  </p>
                  <Separator />
                  <h2>With whom do we share your Data?</h2>
                  <p>
                    The collected Data is shared, according to their respective
                    needs and in accordance with the purposes mentioned above,
                    with:
                  </p>
                  <ul>
                    <li>PREDICTA LAB staff</li>
                    <li>
                      The staff of external hosting services for our website
                    </li>
                    <li>
                      The staff of external services in the context of
                      commercial prospecting
                    </li>
                    <li>The staff of external accounting services</li>
                  </ul>
                  <Separator />
                  <h2>Are your Data sent outside of the European Union?</h2>
                  <p>
                    We may share some of your Data outside of the European Union
                    with service providers located outside of the European
                    Union. However, each transfer is subject to a mechanism that
                    provides an equivalent level of protection as guaranteed by
                    European Union law.
                  </p>
                  <p>What security measures have we taken to protect you?</p>
                  <p>
                    We are committed to implementing all necessary technical and
                    organizational measures to ensure the protection of your
                    Personal Data.
                  </p>
                  <p>
                    For example, we implement the principle of data minimization
                    and have taken out cyber risk insurance.
                  </p>
                  <p>
                    However, PREDICTA LAB cannot be held liable in the event of
                    total or partial non-compliance with an obligation and/or
                    failure of the operators of transport networks to the
                    Internet and, in particular, its internet service providers
                    or computer hardware. The damages due by PREDICTA LAB in the
                    event of a service failure resulting from proven fault on
                    its part shall correspond to the direct, personal, and
                    certain prejudice resulting from the failure in question, to
                    the express exclusion of any indirect damage. In the event
                    of a Data breach and in accordance with regulations,
                    PREDICTA LAB undertakes to notify them to the CNIL as well
                    as to the individuals concerned in the event of a high risk
                    to the rights and freedoms of natural persons, in the
                    conditions provided for by the GDPR.
                  </p>
                  <Separator />
                  <h2>Modification of this Data Protection Policy</h2>
                  <p>
                    We may update this Policy by publishing a new version on our
                    website. We undertake not to substantially lower the level
                    of confidentiality of your Data without informing you and
                    obtaining your consent if necessary.
                  </p>
                  <Separator />
                  <h2>Contact</h2>
                  <p>
                    If you have any questions regarding the Data Protection
                    Policy, please contact us:
                  </p>
                  <ul>
                    <li>
                      By postal mail to the following address:
                      <address>
                        <ul>
                          <li>Predicta Lab</li>
                          <li>Attention: Data Protection Officer (DPO)</li>
                          <li>777 chemin de L’Espérès</li>
                          <li>31370 BEAUFORT</li>
                        </ul>
                      </address>
                    </li>
                    <li>Or by email: rgpd@predictalab.com</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default DataProtectionPolicy;
