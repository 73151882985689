import { useState, useRef } from "react";
import { FiMenu } from "react-icons/fi";
import Logo from "../../elements/logo/Logo";
import MobileMenu from "./MobileMenu";
import useStickyHeader from "./useStickyHeader";
import { Link } from "react-router-dom";
import Nav from "./Nav";
import { useTranslation } from "react-i18next";
import LanguageSelector from "./LanguageSelector";

const Header = ({ btnStyle, HeaderSTyle }) => {
  const { t } = useTranslation();
  const [offcanvasShow, setOffcanvasShow] = useState(false);
  const ref = useRef();
  const sticky = useStickyHeader(50);
  const headerClasses = `header-default ${sticky ? "sticky" : ""}`;
  const onCanvasHandler = () => setOffcanvasShow((p) => !p);
  return (
    <>
      <header
        ref={ref}
        className={`rn-header header-default ${HeaderSTyle} ${headerClasses}`}
      >
        <div className="container position-relative">
          <div className="row align-items-center row--0">
            <div className="col-lg-3 col-md-6 col-4 pl--10">
              <Logo
                image={`${process.env.PUBLIC_URL}/images/logo/predictalab.png`}
                image2={`${process.env.PUBLIC_URL}/images/logo/predictalab.png`}
              />
            </div>
            <div className="col-lg-9 col-md-6 col-8 position-static">
              <div className="header-right">
                <nav className="mainmenu-nav d-none d-lg-block">
                  <Nav />
                </nav>
                <div className="header-btn">
                  <Link className={`btn-default ${btnStyle}`} to="/contact">
                    {t("Contact us", { ns: "Common" })}
                  </Link>
                </div>
                <div className="mobile-menu-bar ml--5 d-block d-lg-none">
                  <div className="hamberger">
                    <span
                      className="hamberger-button"
                      onClick={onCanvasHandler}
                    >
                      <FiMenu />
                    </span>
                  </div>
                </div>

                <div className="d-none d-lg-block">
                  <LanguageSelector />
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <MobileMenu show={offcanvasShow} onClose={onCanvasHandler} />
    </>
  );
};
export default Header;
