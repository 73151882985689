import React, { createRef } from "react";
import { createPortal } from "react-dom";
import ReCAPTCHA from "react-google-recaptcha";

const useRecaptcha = ({ action }) => {
  const recaptchaRef = createRef();

  const Recaptcha = createPortal(
    <ReCAPTCHA
      ref={recaptchaRef}
      sitekey="6LfHqw0pAAAAAOnexwT1KSNHJ5NsUSfkTHGq1FN5"
      size="invisible"
      onChange={(token) => action(token)}
      theme="dark"
    />,
    document.body
  );

  const recaptchaAction = (e) => {
    e.preventDefault();
    recaptchaRef.current?.execute();
  };

  return {
    Recaptcha,
    recaptchaAction,
  };
};
export default useRecaptcha;
