import React from "react";
import Copyright from "./footer/Copyright";
import Header from "./header/Header";
import Footer from "./footer/Footer";

const Layout = ({ children }) => {
  return (
    <main className="page-wrapper">
      <Header
        btnStyle="btn-small round btn-icon"
        HeaderSTyle="header-not-transparent"
      />
      {children}
      <Footer />
      <Copyright />
    </main>
  );
};
export default Layout;
