import React from "react";
import Seo from "../common/SEO";
import Layout from "../common/Layout";
import Separator from "../elements/separator/Separator";
import ProductsItem from "../elements/products/ProductsItem";
import { Trans, useTranslation } from "react-i18next";
import Breadcrumb from "../elements/breadcrumb/Breadcrumb";

const Products = () => {
  const { t } = useTranslation("Products");
  return (
    <>
      <Seo title="Products - We develop innovative tools that provide comprehensive analysis of publicly available data for informed decision-making." />
      <Layout>
        <Breadcrumb
          title={t("Our products")}
          rootUrl="/"
          parentUrl={t("Home", { ns: "Common" })}
          currentUrl={t("Products")}
        />

        <div className="main-content">
          <ProductsItem
            id="predictasearch"
            image="./images/products/predictasearch.jpeg"
            title={
              <Trans t={t}>
                Predicta <span className="theme-gradient">Search</span>
              </Trans>
            }
            link="https://www.predictasearch.com"
            linkText="Try it! It's free"
            content={[
              {
                title:
                  "Get your digital footprint from an email or phone number",
                desc: "Predicta Search allows you to get the digital footprint from an email or phone number. You can get the social media profiles, websites, and more from a person or company.",
              },
              {
                title: "How does it work?",
                desc: "You can search for a person or company by email or phone number. Predicta Search will show you the social media profiles, websites, and more from the person or company.",
              },
            ]}
          />

          <Separator />

          <ProductsItem
            id="predictagraph"
            image="./images/products/predictagraph.jpg"
            title={
              <Trans t={t}>
                Predicta <span className="theme-gradient">Graph</span>
              </Trans>
            }
            link="https://www.predictagraph.com"
            linkText="Try it! It's free"
            content={[
              {
                title: "Explore and analyse data",
                desc: "More than a graph, a real investigation tool to help you explore and analyse data.",
              },
              {
                title: "How does it work?",
                desc: "Predicta Graph is a tool that allows you to explore and analyse data. We have built a custom API that allows you to fetch posts, comments, media, and more.",
              },
            ]}
          />

          <Separator />

          <ProductsItem
            id="dorkgpt"
            image="./images/products/dorkgpt.jpeg"
            title={
              <>
                Dork<span className="theme-gradient">GPT</span>
              </>
            }
            link="https://www.dorkgpt.com"
            linkText="Try it! It's free"
            content={[
              {
                title: "Build the perfect search query using AI",
                desc: "Type in in full words what you are looking for and DorkGPT will create a query that will find precisely what you need using Google search operators.",
              },
              {
                title: "How does it work?",
                desc: "DorkGPT is powered by Open AI’s most notorious artificial intelligence, ChatGPT.",
              },
            ]}
          />
        </div>
      </Layout>
    </>
  );
};
export default Products;
