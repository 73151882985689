import React from "react";
import { Accordion, Card, Button } from "react-bootstrap";
import { FiTwitch, FiInstagram, FiLinkedin, FiYoutube } from "react-icons/fi";
import { FaXTwitter, FaTiktok } from "react-icons/fa6";
import { useTranslation } from "react-i18next";

const MissionAccordion = () => {
  const { t } = useTranslation("Mission");
  const content = [
    {
      title: "What is OSINT?",
      description:
        "Open Source INTelligence is the practice of gathering and analyzing information from publicly available sources to produce intelligence and insight.",
    },
    {
      title: "What sources do your tools use?",
      description:
        "Our tools rely exclusively on publicly available information and they are all compliant with the EU General Data Protection Regulation.",
    },
    {
      title: "Who’s behind Predicta Lab?",
      description:
        "Baptiste Robert and Thierry Revelin founded Predicta Lab together in 2020. Follow Baptiste social media accounts:",
      social: (
        <div className="row mt--30">
          <div className="col-lg-12">
            <ul className="social-icon social-default with-gradient">
              <li>
                <a
                  href="https://www.linkedin.com/in/baptisterobert/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FiLinkedin />
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/fs0c131y"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaXTwitter />
                </a>
              </li>
              <li>
                <a
                  href="https://www.tiktok.com/@baptisterobertoff"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaTiktok />
                </a>
              </li>
              <li>
                <a
                  href="https://www.twitch.tv/fs0c131yoff"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FiTwitch />
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/@baptisterobertoff"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FiYoutube />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/baptisterobertoff"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FiInstagram />
                </a>
              </li>
            </ul>
          </div>
        </div>
      ),
    },
    {
      title: "How can I join Predicta Lab?",
      description:
        "If you are a trained developer, OSINT analyst or if you have development skills in Python or React, send us your CV at contact@predictalab.com",
    },
  ];

  return (
    <Accordion className={`rn-accordion-style`} defaultActiveKey="0">
      {content.map((item, index) => {
        return (
          <Card key={item.title}>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" eventKey={index + 1}>
                {t(item.title)}
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey={index + 1}>
              <Card.Body>
                {t(item.description)}
                {item?.social}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        );
      })}
    </Accordion>
  );
};
export default MissionAccordion;
