import React from "react";
import Seo from "../common/SEO";
import Layout from "../common/Layout";
import Breadcrumb from "../elements/breadcrumb/Breadcrumb";

const LegalNotice = () => {
  return (
    <>
      <Seo title="Legal notice" />
      <Layout>
        <Breadcrumb
          title="Legal notice"
          rootUrl="/"
          parentUrl="Home"
          currentUrl="Legal notice"
        />

        <div className="main-content">
          <div className="products about-style-4 rn-section-gap">
            <div className="container">
              <div className="row">
                <div className="col">
                  <p>
                    The present website, accessible at the URL{" "}
                    <a
                      href="https://www.predictalab.fr"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://www.predictalab.fr
                    </a>
                    , is edited by Predicta LAB (below « Predicta Lab »). It is
                    the property of Predicta Lab, a simplified joint-stock
                    company with a share capital of 10,000 euros, registered
                    with the Toulouse Trade and Companies Register under the
                    number B 882 794 548 and with an intra-community VAT number
                    FR28882794548.
                  </p>

                  <p>
                    The publishing director of this website is Baptiste Robert,
                    in his capacity as President of Predicta Lab.
                  </p>

                  <h3>Contact Information:</h3>
                  <address>
                    <ul style={{ listStyleType: "none" }}>
                      <li>Predicta Lab</li>
                      <li>Attention: Data Protection Officer (DPO)</li>
                      <li>777 chemin de L’Espérès</li>
                      <li>31370 BEAUFORT</li>
                      <li>0756898205</li>
                      <li>contact@predictalab.com</li>
                    </ul>
                  </address>

                  <h3>Website Hosting:</h3>
                  <address>
                    <ul style={{ listStyleType: "none" }}>
                      <li>OVH SAS</li>
                      <li>2 rue Kellermann – BP 80157 59053 ROUBAIX CEDEX 1</li>
                    </ul>
                  </address>
                  <address>
                    <ul style={{ listStyleType: "none" }}>
                      <li>DigitalOcean SIC 73,737</li>
                      <li>101 6th Avenue, New York, NY 10013, USA</li>
                      <li>contact@digitalocean.com</li>
                    </ul>
                  </address>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default LegalNotice;
