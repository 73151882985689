import React, { useState } from "react";
import useRecaptcha from "../../hooks/useRecaptcha";
import { useTranslation } from "react-i18next";

const initialContactValues = {
  name: "",
  email: "",
  phone_number: "",
  subject: "",
  message: "",
};

function ContactForm({ props, formStyle }) {
  const { t } = useTranslation("Contact");
  const [contactInfos, setContactInfos] = useState(initialContactValues);
  const [feedback, setFeedback] = useState({ type: "", message: "" });

  const { Recaptcha, recaptchaAction } = useRecaptcha({
    action: (token) => handleContact(token),
  });

  const handleContact = (token) => {
    if (contactInfos.message.length < 10) {
      setFeedback({ type: "error", message: t("Your message is too short") });
      return;
    }

    async function sendMail() {
      const url = `https://newsletter.predictalab.fr/contact/?g_recaptcha_response=${token}`;
      const payload = contactInfos;

      const response = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      return await response.json();
    }

    sendMail()
      .then(() => {
        setContactInfos(initialContactValues);
        setFeedback({
          type: "success",
          message: t("Your message has been sent successfully"),
        });
      })
      .catch((error) => {
        setFeedback({
          type: "error",
          message: error ?? t("Error occured, please try again later"),
        });
      });
  };

  const handleSetContactInfos = (e) => {
    setContactInfos((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  return (
    <form className={`${formStyle}`} onSubmit={(e) => recaptchaAction(e)}>
      <div className="form-group">
        <input
          type="text"
          name="name"
          placeholder={t("Your Name")}
          required
          value={contactInfos.name}
          onChange={handleSetContactInfos}
        />
      </div>

      <div className="form-group">
        <input
          type="email"
          name="email"
          placeholder={t("Email Address")}
          required
          value={contactInfos.email}
          onChange={handleSetContactInfos}
        />
      </div>

      <div className="form-group">
        <input
          type="text"
          name="phone_number"
          placeholder={t("Phone Number")}
          value={contactInfos.phone_number}
          onChange={handleSetContactInfos}
        />
      </div>

      <div className="form-group">
        <input
          id="subject"
          type="text"
          name="subject"
          minLength="5"
          maxLength="50"
          placeholder={t("Subject")}
          value={contactInfos.subject}
          required
          onChange={handleSetContactInfos}
        />
      </div>

      <div className="form-group">
        <textarea
          id="message"
          name="message"
          minLength={10}
          maxLength={500}
          placeholder={t("Your message")}
          value={contactInfos.message}
          required
          onChange={handleSetContactInfos}
        />
      </div>

      <div className="form-group">
        <div className="row align-items-center">
          <div className="col-12 col-md-auto">
            <button className="btn-default btn-large">
              {t("Send message")}
            </button>
          </div>

          {feedback.message && (
            <div className="col-12 col-md mt-4 mt-md-0">
              <div
                className={
                  feedback.type === "success" ? "text-success" : "text-danger"
                }
                role="alert"
              >
                {t(feedback.message)}
              </div>
            </div>
          )}
        </div>
      </div>

      {Recaptcha}
    </form>
  );
}
export default ContactForm;
