import React from "react";
import Seo from "../common/SEO";
import Layout from "../common/Layout";
import Title from "../elements/sectionTitle/Title";
import ServicesItem from "../elements/service/ServicesItem";
import { FiActivity, FiArrowRight } from "react-icons/fi";
import { CgWebsite } from "react-icons/cg";
import { AiOutlineSecurityScan, AiOutlineClear } from "react-icons/ai";
import { MdOutlineOfflineBolt, MdManageSearch } from "react-icons/md";
import { TbReportAnalytics } from "react-icons/tb";
import { FaServer } from "react-icons/fa6";
import Separator from "../elements/separator/Separator";
import TrainingCoursesTimeline from "../elements/timeline/TrainingCoursesTimeline";
import { Trans, useTranslation } from "react-i18next";
import Breadcrumb from "../elements/breadcrumb/Breadcrumb";
import ScrollAnimation from "react-animate-on-scroll";
import { Link } from "react-router-dom";

const Services = () => {
  const { t } = useTranslation("Services");
  return (
    <>
      <Seo title="Services - Whether it is for data collection, content analysis or web monitoring, we provide the right service for your OSINT-related needs." />
      <Layout>
        <Breadcrumb
          title={t("Our services")}
          rootUrl="/"
          parentUrl={t("Home", { ns: "Common" })}
          currentUrl={t("Services")}
        />

        <div className="main-content">
          {/* INVESTIGATIONS */}
          <div className="rn-service-area rn-section-gap" id="investigations">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <Title
                    textAlign="text-center"
                    title={
                      <Trans t={t}>
                        <span className="theme-gradient">Investigations</span>
                      </Trans>
                    }
                  />
                </div>
              </div>

              <div className="row mt--30">
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  animateOnce={true}
                >
                  <div className="col text-center">
                    <h3>{t("Digital footprint report")}</h3>
                    <p className="text-balance">
                      {t(
                        "We gather in a single report all your publicly available data from the clear web, social media, the dark web and more."
                      )}
                    </p>
                  </div>
                </ScrollAnimation>
              </div>

              <ServicesItem
                data={[
                  {
                    icon: <AiOutlineSecurityScan />,
                    title: "Security and Privacy Protection",
                    description:
                      "Identify potential vulnerabilities and exposures to enhance your security online.",
                  },
                  {
                    icon: <MdManageSearch />,
                    title: "Reputation Management",
                    description:
                      "Being aware of your online presence allows you to safeguard your personal and professional reputation.",
                  },
                  {
                    icon: <AiOutlineClear />,
                    title: "Assistance in removing data",
                    description:
                      "Our reports include personalised advices and guidance on how to remove information online.",
                  },
                ]}
              />

              <div className="row mt--30">
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  animateOnce={true}
                >
                  <div className="col text-center">
                    <h3>{t("Monitoring")}</h3>
                    <p className="text-balance">
                      {t(
                        "For an individual or a theme, we provide monthly reports of online activity about them on the sources of your choice."
                      )}
                    </p>
                  </div>
                </ScrollAnimation>
              </div>

              <ServicesItem
                data={[
                  {
                    icon: <FiActivity />,
                    title: "Early Detection of Threats",
                    description:
                      "Detection of data breaches, harmful content and more to enable timely responses.",
                  },
                  {
                    icon: <MdManageSearch />,
                    title: "Reputation Management",
                    description:
                      "Allows you to address negative sentiments, misinformation, or public relations crises promptly.",
                  },
                  {
                    icon: <TbReportAnalytics />,
                    title: "Tailored to Your Needs",
                    description:
                      "Be it LinkedIn, the dark web or Telegram, we monitor the sources and trends you need.",
                  },
                ]}
              />
            </div>
          </div>

          <Separator />

          {/* SOFTWARE DEV */}
          <div className="rn-service-area rn-section-gap" id="development">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <Title
                    textAlign="text-center"
                    title={
                      <Trans t={t}>
                        Software{" "}
                        <span className="theme-gradient">Development</span>
                      </Trans>
                    }
                    description={t(
                      "Our OSINT software development service creates cutting-edge tools for efficient data collection, analysis, and intelligence gathering from public sources, enhancing decision-making and strategic insights for diverse industries."
                    )}
                  />
                </div>
              </div>
              <ServicesItem
                data={[
                  {
                    icon: <CgWebsite />,
                    title: "Intuitive Design",
                    description: "Expertise in UI/UX, web design.",
                  },
                  {
                    icon: <FaServer />,
                    title: "Reliable Backend",
                    description: "Robustness and high uptime.",
                  },
                  {
                    icon: <MdOutlineOfflineBolt />,
                    title: "Custom Solutions",
                    description: "Developments tailored to client needs.",
                  },
                ]}
              />
            </div>
          </div>

          <Separator />

          {/* OSINT TRAINING COURSES */}
          <div className="rn-service-area rn-section-gap" id="trainingcourses">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <Title
                    textAlign="text-center"
                    title={
                      <Trans t={t}>
                        Training <span className="theme-gradient">courses</span>
                      </Trans>
                    }
                    description={t(
                      "Predicta Lab delivers a 3-day entry-level course in OSINT practices. This training touches upon the guiding   principles of online investigation as well as the main techniques that make OSINT essential in today’s information environment. The cursus is divided in 4 modules:"
                    )}
                  />
                </div>
              </div>

              <div className="mt--30">
                <TrainingCoursesTimeline classVar="dark-line" />
              </div>

              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <div className="row">
                  <div className="col-lg-12 text-center mt--20">
                    <p className="text-balance">
                      {t(
                        "Classes are delivered by one of our experienced OSINT analysts. This course can be customized according to the clients needs and objectives"
                      )}
                    </p>
                  </div>
                </div>
              </ScrollAnimation>
            </div>
          </div>

          <Separator />

          {/* CONFERENCES */}
          <div className="rn-service-area rn-section-gap" id="conferences">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <Title
                    textAlign="text-center"
                    title={
                      <Trans t={t}>
                        <span className="theme-gradient">Conferences</span>
                      </Trans>
                    }
                    description={t(
                      "Our CEO, Baptiste Robert, is a recognised ethical hacker and OSINT practitioner. He frequently gives out conferences about the security of personal data, the digital footprint and open source intelligence."
                    )}
                  />

                  <ScrollAnimation
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}
                  >
                    <p className="text-center mt--30">
                      <h5 className="w-500">
                        {t("Looking for a speaker at your conference?")}{" "}
                        <Link
                          className="btn-default btn-small btn-icon ms-md-3 mt-4 mt-md-0"
                          to="contact"
                        >
                          {t("Contact us", { ns: "Common" })}
                          <i className="icon">
                            <FiArrowRight />
                          </i>
                        </Link>
                      </h5>
                    </p>
                  </ScrollAnimation>

                  <ScrollAnimation
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}
                  >
                    <div className="row mt--30 conferences-gallery">
                      <div className="col-12 col-lg-4">
                        <div className="ratio ratio-16x9 my-3 my-lg-0">
                          <img
                            src="./images/services/conf/french_tech2.jpg"
                            alt="Conferences"
                            className="object-fit-cover h-100 w-100"
                          />
                        </div>
                      </div>

                      <div className="col-12 col-lg-4">
                        <div className="ratio ratio-16x9 my-3 my-lg-0">
                          <img
                            src="./images/services/conf/devfest.jpeg"
                            alt="Conferences"
                            className="object-fit-cover h-100 w-100"
                          />
                        </div>
                      </div>
                      <div className="col-12 col-lg-4">
                        <div className="ratio ratio-16x9 my-3 my-lg-0">
                          <img
                            src="./images/services/conf/tedx.jpeg"
                            alt="Conferences"
                            className="object-fit-cover h-100 w-100"
                          />
                        </div>
                      </div>
                    </div>
                  </ScrollAnimation>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Services;
