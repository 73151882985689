import { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

const initValue = localStorage.getItem("cookieConsent");

const CookieConsent = () => {
  const { t } = useTranslation("CookieConsent");
  const [consentGiven, setConsentGiven] = useState(initValue ?? "init");

  // Check for existing consent in LocalStorage on app load
  useEffect(() => {
    const storedConsent = localStorage.getItem("cookieConsent");
    if (storedConsent === "true") {
      setConsentGiven(true);
    }
  }, []);

  // Save consent status to LocalStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("cookieConsent", consentGiven.toString());
  }, [consentGiven]);

  // Load Google Analytics script if consent is given
  useEffect(() => {
    if (consentGiven === true) {
      // Include Google Analytics script dynamically
      ReactGA.initialize("G-ZQKX33N8YK");
    }
  }, [consentGiven]);

  return (
    <>
      {consentGiven === "init" && (
        <div className="cookieWrapper">
          <p>
            <Trans t={t}>
              We may store and access personal data such as cookies, device
              identifiers or other similar technologies on your device and
              process such data analyse our traffic. By clicking 'I understand'
              you will allow the use of these cookies. Learn more about our{" "}
              <Link to="/data-protection-policy">privacy policy</Link>.
            </Trans>
          </p>

          <div className="btns">
            <button
              onClick={() => setConsentGiven(true)}
              className="btn-default btn-small btn-icon"
            >
              {t("I understand")}
            </button>
            <button
              onClick={() => setConsentGiven(false)}
              className="btn-default btn-small btn-border btn-icon"
            >
              {t("I decline")}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default CookieConsent;
