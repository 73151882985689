import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

const Title = ({ subtitle, title, description, textAlign, radiusRounded }) => {
  return (
    <div className={`section-title ${textAlign}`}>
      {subtitle && (
        <ScrollAnimation
          animateIn="fadeInUp"
          animateOut="fadeInOut"
          animateOnce={true}
        >
          <h4 className={`subtitle ${radiusRounded}`}>
            <span className="theme-gradient">{subtitle}</span>
          </h4>
        </ScrollAnimation>
      )}

      <ScrollAnimation
        animateIn="fadeInUp"
        animateOut="fadeInOut"
        animateOnce={true}
      >
        <h2 className="title w-500 mb--20">{title}</h2>
      </ScrollAnimation>

      <div className="row">
        <div className="col-10 col-md-6 mx-auto">
          <ScrollAnimation
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}
          >
            <p className="description b1 text-balance m-0">{description}</p>
          </ScrollAnimation>
        </div>
      </div>
    </div>
  );
};
export default Title;
