import { useCallback, useMemo } from "react";
import { GrLanguage } from "react-icons/gr";
import { useTranslation } from "react-i18next";

const LanguageSelector = (mobile) => {
  const { i18n } = useTranslation("LanguageSelector");

  const getLanguage = useCallback(
    () => i18n.language || window.localStorage.i18nextLng,
    [i18n]
  );

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("lng", lng);
  };

  const isCurrentLng = useMemo(
    () => (lng) => lng === getLanguage(),
    [getLanguage]
  );

  return (
    <div className="language-selector ms-3 ms-md-5" data-mobile={mobile}>
      <ul>
        <li>
          <span className="icon-wrapper">
            <GrLanguage size={20} />
            <small>{getLanguage()}</small>
          </span>

          <ul className="lgns">
            <li
              onClick={() => changeLanguage("en")}
              data-current={isCurrentLng("en")}
            >
              English
            </li>
            <li
              onClick={() => changeLanguage("fr")}
              data-current={isCurrentLng("fr")}
            >
              French
            </li>
            <li
              onClick={() => changeLanguage("es")}
              data-current={isCurrentLng("es")}
            >
              Spanish
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default LanguageSelector;
