import Seo from "../common/SEO";
import Layout from "../common/Layout";
import { useTranslation } from "react-i18next";
import Breadcrumb from "../elements/breadcrumb/Breadcrumb";
import {
  articles2020,
  articles2021,
  articles2022,
  articles2023,
  articles2024,
} from "./pressArticles";

const Press = () => {
  const { t } = useTranslation("Press");

  function parseDate(dateStr) {
    const parts = dateStr.split("/");
    const date = new Date(parts[2], parts[1] - 1, parts[0]);
    return date;
  }

  const renderArticles = (articles) => {
    // Sort articles by date
    articles.sort((a, b) => parseDate(b.date) - parseDate(a.date));

    return (
      <ul>
        {articles.map((article) => (
          <li key={article.url}>
            <span
              style={{ width: "12rem", opacity: 0.7 }}
              className="d-block d-md-inline-block"
            >
              {article.date}
            </span>
            <a
              href={article.url}
              target="_blank"
              rel="noreferrer"
              alt={article.title}
            >
              {article.title ?? article.url}
            </a>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <>
      <Seo title="Press & Media - Articles about our company" />
      <Layout>
        <Breadcrumb
          title={t("Press")}
          rootUrl="/"
          parentUrl={t("Home", { ns: "Common" })}
          currentUrl={t("Press")}
        />
        <div className="main-content">
          <div className="products rn-section-gap">
            <div className="container">
              <div className="row">
                <div className="col">
                  <h3>2024</h3>
                  {renderArticles(articles2024)}

                  <h3>2023</h3>
                  {renderArticles(articles2023)}

                  <h3>2022</h3>
                  {renderArticles(articles2022)}

                  <h3>2021</h3>
                  {renderArticles(articles2021)}

                  <h3>2020</h3>
                  {renderArticles(articles2020)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Press;
