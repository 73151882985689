import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const Nav = () => {
  const { t } = useTranslation("Nav");
  const { hash, pathname, key } = useLocation();

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === "") {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          // with an offset of 100px
          const y =
            element.getBoundingClientRect().top + window.pageYOffset - 100;
          window.scrollTo({ top: y, behavior: "smooth" });
        }
      }, 0);
    }
  }, [pathname, hash, key]); // do this on route change

  return (
    <ul className="mainmenu">
      <li className="has-droupdown">
        <Link to="/">{t("Home")}</Link>
      </li>

      <li>
        <Link to="/#about">{t("About")}</Link>
      </li>

      <li className="has-droupdown">
        <Link to="/products">{t("Products")}</Link>
        <ul className="submenu">
          <li>
            <Link to="/products#predictasearch">Predicta Search</Link>
          </li>
          <li>
            <Link to="/products#predictagraph">Predicta Graph</Link>
          </li>
          <li>
            <Link to="/products#dorkgpt">DorkGPT</Link>
          </li>
        </ul>
      </li>

      <li className="has-droupdown">
        <Link to="/services">{t("Services")}</Link>
        <ul className="submenu">
          <li>
            <Link to="/services#investigations">{t("Investigations")}</Link>
          </li>
          <li>
            <Link to="/services#development">{t("Software development")}</Link>
          </li>
          <li>
            <Link to="/services#trainingcourses">{t("Training courses")}</Link>
          </li>
          <li>
            <Link to="/services#conferences">{t("Conferences")}</Link>
          </li>
        </ul>
      </li>

      <li>
        <Link to="/white-paper">{t("White paper")}</Link>
      </li>

      <li>
        <Link to="/press">{t("Press")}</Link>
      </li>
    </ul>
  );
};
export default Nav;
