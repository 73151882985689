import React from "react";
import Title from "../sectionTitle/Title";
import { useTranslation, Trans } from "react-i18next";
import MissionAccordion from "../accordion/MissionAccordion";

const Mission = () => {
  const { t } = useTranslation("Mission");

  return (
    <div className="rn-company-mission-are rn-section-gap">
      <div className="container">
        <div className="row row--30">
          <div className="col-lg-6">
            <div className="mission-title">
              <Title
                title={
                  <Trans t={t}>
                    Unique <span className="theme-gradient">mission</span>
                  </Trans>
                }
              />
              <p>
                {t(
                  "At Predicta Lab, our mission is to safeguard individuals and organizations from digital threats by harnessing the power of OSINT."
                )}
              </p>
              <p>
                {t(
                  "We are committed to collecting and disseminating critical information, fostering awareness, and equipping people with the tools and knowledge to protect themselves in the digital world."
                )}
              </p>
            </div>
          </div>
          <div className="col-lg-6 mt_md--30 mt_sm--30">
            <MissionAccordion />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Mission;
