import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { useTranslation } from "react-i18next";

const TimelineData = [
  {
    id: "1",
    title: "OPSEC",
    description: "Measures that protect your analyst during an investigation.",
  },
  {
    id: "2",
    title: "SOCMINT",
    description: "Collection and analysis of information from social media.",
  },
  {
    id: "3",
    title: "GEOINT",
    description: "Geospatial intelligence from imagery.",
  },
  {
    id: "4",
    title: "Dark web",
    description: "Investigation on the darkwebs.",
  },
];

const TrainingCoursesTimeline = ({ classVar }) => {
  const { t } = useTranslation("ServicesItem");
  return (
    <div className="timeline-style-two bg-color-blackest">
      <div className="row row--0">
        {/* Start Single Progress */}
        {TimelineData.map((data, index) => (
          <div
            className={`col-lg-3 col-md-3 rn-timeline-single ${classVar}`}
            key={index}
          >
            <div className="rn-timeline">
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <h6 className="title">{t(data.title)}</h6>
              </ScrollAnimation>
              <div className="progress-line">
                <div className="line-inner"></div>
              </div>
              <div className="progress-dot">
                <div className="dot-level">
                  <div className="dot-inner"></div>
                </div>
              </div>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <p className="description text-balance">
                  {t(data.description)}
                </p>
              </ScrollAnimation>
            </div>
          </div>
        ))}
        {/* End Single Progress */}
      </div>
    </div>
  );
};
export default TrainingCoursesTimeline;
