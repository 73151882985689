import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { useTranslation } from "react-i18next";
import useBookingModal from "../../hooks/useBookingModal";

const callToActionData = {
  title: "Want a live demo ?",
  subtitle: "Reach out and we will get back to you in no time.",
  btnText: "Contact us",
};

const Cta = () => {
  const { t } = useTranslation("Cta");
  const { BookingModal } = useBookingModal({ showButton: true });

  return (
    <div className="rn-callto-action clltoaction-style-default style-5">
      <div className="container">
        <div className="row row--0 align-items-center content-wrapper theme-shape">
          <div className="col-lg-12">
            <div className="inner">
              <div className="content text-center">
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  animateOnce={true}
                >
                  <h2 className="title">{t(callToActionData.title)}</h2>
                </ScrollAnimation>
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  animateOnce={true}
                >
                  <h6 className="subtitle text-balance">
                    {t(callToActionData.subtitle)}
                  </h6>
                </ScrollAnimation>

                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  animateOnce={true}
                >
                  <div className="call-to-btn text-center">{BookingModal}</div>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Cta;
