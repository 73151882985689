import React from "react";
import Logo from "../../elements/logo/Logo";
import Nav from "./Nav";
import { FiX as CloseIcon } from "react-icons/fi";
import LanguageSelector from "./LanguageSelector";

const MobileMenu = ({ show, onClose }) => {
  const links = document.querySelectorAll(".popup-mobile-menu a");

  for (const i in links) {
    if (links.hasOwnProperty(i)) {
      links[i].onclick = function () {
        onClose();
      };
    }
  }

  return (
    <div className={`popup-mobile-menu ${show ? "active" : ""}`}>
      <div className="inner">
        <div className="header-top">
          <Logo
            image={`${process.env.PUBLIC_URL}/images/logo/predictalab.png`}
            image2={`${process.env.PUBLIC_URL}/images/logo/predictalab.png`}
          />

          <div className="close-menu">
            <LanguageSelector mobile={true} />
            <span className="close-button" onClick={onClose}>
              <CloseIcon />
            </span>
          </div>
        </div>
        <Nav />
      </div>
    </div>
  );
};
export default MobileMenu;
