import React from "react";
import Seo from "../common/SEO";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import Copyright from "../common/footer/Copyright";
import Separator from "../elements/separator/Separator";
import Mission from "../elements/about/Mission";
import Footer from "../common/footer/Footer";
import Header from "../common/header/Header";
import Title from "../elements/sectionTitle/Title";
import { Trans, useTranslation } from "react-i18next";
import HomepageProducts from "../elements/advancetab/HomepageProducts";
import HomepageServices from "../elements/service/HomepageServices";
import Cta from "../elements/calltoaction/Cta";
import Blog from "../components/blog/Blog";

const HomePage = () => {
  const { t } = useTranslation("Homepage");

  return (
    <>
      <Seo title="Homepage - Predicta Lab protect yourself from digital threats" />
      <main className="page-wrapper">
        {/* Header */}
        <Header
          btnStyle="btn-small round btn-icon"
          HeaderSTyle="header-transparent"
        />

        <div className="slider-area slider-style-1 bg-transparent height-650">
          <div className="container mt--50">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner text-center">
                  <h1 className="title theme-gradient display-two">
                    {t("Protect your digital life")}
                  </h1>
                  <p className="description">
                    {t("Arm yourself against digital threats")}
                  </p>
                  <div className="button-group">
                    <Link
                      className="btn-default btn-medium round btn-icon"
                      to="/#about"
                    >
                      {t("Learn more")}
                      <i className="icon">
                        <FiArrowRight />
                      </i>
                    </Link>
                    <Link
                      className="btn-default btn-medium btn-border round btn-icon"
                      to="/contact"
                    >
                      {t("Contact Us")}{" "}
                      <i className="icon">
                        <FiArrowRight />
                      </i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Separator />

        {/* Our products */}
        <div className="rwt-advance-tab-area rn-section-gap">
          <div className="container">
            <div className="row mb--40">
              <div className="col-lg-12">
                <Title
                  textAlign="text-center"
                  title={
                    <Trans t={t}>
                      Our <span className="theme-gradient">products</span>
                    </Trans>
                  }
                  description={t(
                    "We develop innovative tools that provide comprehensive analysis of publicly available data for informed decision-making."
                  )}
                />
              </div>
            </div>
            <HomepageProducts />
          </div>
        </div>

        <Separator />

        {/* Our services */}
        <div className="rn-service-area rn-section-gap ">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <Title
                  textAlign="text-center"
                  title={
                    <Trans t={t}>
                      Our <span className="theme-gradient">Services</span>
                    </Trans>
                  }
                  description={t(
                    "Whether it is for data collection, content analysis or web monitoring, we provide the right service for your OSINT-related needs."
                  )}
                />
              </div>
            </div>
            <HomepageServices />
          </div>
        </div>

        <Separator />

        {/* CTA */}
        <div className="rwt-callto-action-area rn-section-gap">
          <div className="wrapper">
            <Cta />
          </div>
        </div>

        <Separator />

        {/* Blog */}
        <Blog />

        <Separator />

        {/* Our mission */}
        <div id="about">
          <Mission />
        </div>

        <Footer />
        <Copyright />
      </main>
    </>
  );
};
export default HomePage;
