import React from "react";
import PropTypes from "prop-types";

const BlogList = ({ data, StyleVar }) => {
  return (
    <div className={`rn-card ${StyleVar}`}>
      <div className="inner">
        <div className="thumbnail">
          <a
            href={data.link}
            className="ratio ratio-16x9 d-block"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={data.image_url}
              alt={data.title}
              className="object-fit-cover"
            />
          </a>
        </div>
        <div className="content">
          <ul className="rn-meta-list">
            <li>{data.published}</li>
          </ul>
          <h4 className="title">
            <a href={data.link} target="_blank" rel="noopener noreferrer">
              {data.title}
            </a>
          </h4>
        </div>
      </div>
    </div>
  );
};
BlogList.propTypes = {
  data: PropTypes.object,
};
export default BlogList;
